header {
  /*100vh would mean 100percent of the heigh of the screen or viewport */
  height: 150vh;
  /* gives the header some additional space at the top */
  padding-top: 7rem;
  /*hide addtional content that overflows out of header*/
  overflow: hidden;
  /*background-color: red;*/
}

.header__container {
  /*make all the children align center but the flex attribute of the children overrides this*/
  text-align: center;
  height: 100%;
  position: relative;
}

/* ========= CTA ===== */
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/* ========= HEADER SOCIALS ===== */
.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem; /* always 3 rem from the bottom of its parent container */
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/* ========= ME ===== */
.me {
  background: linear-gradient(var(--color-primary), transparent);
  width: 22rem;
  height: 30rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 4rem;
  border-radius: 12rem 12rem 0 0;
  overflow: hidden;
  padding: 3rem 1rem 1rem 1rem;
}

/* ========= SCROLL DOWN ===== */
.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
}

/* ============= MEDIA QUERIES (MEDIUM DEVICES) ======= */
@media screen and (max-width: 1024px) {
  header {
    height: 68vh;
  }
}

/* ============= MEDIA QUERIES (SMALL DEVICES) ======= */
@media screen and (max-width: 600px) {
  header {
    height: 150vh;
  }

  .header__socials,
  .scroll__down {
    display: none;
  }
}
