nav {
  background: rgba(0, 0, 0, 0.3);
  width: max-content;
  height: fit-content;
  display: block;
  padding: 0.7rem 1.7rem;
  z-index: 2;
  position: fixed;
  left: 90%;
  transform: translate(-50%);
  bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  border-radius: 3rem;
  backdrop-filter: blur(15px);
}

nav a {
  background: transparent;
  padding: 0.9rem;
  border-radius: 50%;
  display: flex;
  color: var(--color-light);
}

nav a:hover {
  background: rgba(0, 0, 0, 0.3);
}

nav a.active {
  background: var(--color-primary);
  color: var(--color-bg);
}
